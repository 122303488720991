import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BackEndService from "../../api/BackEndService";
import MonitorServiceAction from "../../api/MonitorService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomCollapse } from "../../components/CustomCollapse/CustomCollapse";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { MultiImageUploader2 } from "../../components/MultiImageUploader2/MultiImageUploader2";
import { ServiceInfoBox } from "../../components/ServiceInfoBox/ServiceInfoBox";
import { useHandleServiceImages } from "../../hooks/useHandleServiceImages";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { goOneStepBack } from "../../services/NavigationService";
import { RootState } from "../../store";
import { creatingServiceActions } from "../../store/job-slice";
import { MonitorEvent } from "../../store/serviced-monitors";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/issues-service.module.css";
import { MinimalProduct } from "../../types/product";
import { ResponseMeta, ResponseWarningQueries } from "../../types/response";

export const NoIssuesService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [error, setError] = useState("");
  const [monitorEventId, setMonitorEventId] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const [date, setDate] = useState("");

  const { handleImageUploaded, handleImageDelete } = useHandleServiceImages();
  const appointment = useSelector((state: RootState) => state.appointment);
  const service = useSelector((state: RootState) => state.service);
  const monitor = useSelector((state: RootState) => state.monitor.monitor);
  const servicedMonitors = useSelector(
    (state: RootState) => state.servicedMonitors.servicedMonitors
  );
  const monitorEvents = useSelector(
    (state: RootState) => state.servicedMonitors.monitorEvents
  );

  const [products, setProducts] = useState<MinimalProduct[]>([]);

  const handleTextareaChange = (note: string) => {
    dispatch(creatingServiceActions.addComment(note));
  };

  const returnToDashboard = () => {
    dispatch(activeTabsActions.setDashbaordUrl(""));
    dispatch(activeTabsActions.setMonitorsStep(2));
    dispatch(creatingServiceActions.resetState());

    navigate(`/properties/${appointment.id}/show`);
  };

  const createMonitorEvent = useCallback(async () => {
    try {
      setRequestLoading(true);
      const monitorServiced = servicedMonitors?.includes(monitor!.id);

      let response: { data: MonitorEvent } & ResponseMeta &
        ResponseWarningQueries;
      const payload = {
        monitorId: monitor!.id,
        eventType: service.eventType,
        jobId: appointment?.jobs?.slice(-1).pop().id,
        comment: service.comment,
        sevdesk_products: [],
      };

      if (monitorServiced) {
        response = await MonitorServiceAction.UpdateMonitorEvent(
          monitorEventId,
          payload
        );
      } else {
        response = await MonitorServiceAction.CreateMonitorEvent(payload);
      }

      if (response.data) {
        if (service.images.length > 0) {
          const imageUploadPromises = service.images.map((image) => {
            return BackEndService.AddDocument({
              documentType: "userUpload",
              fileName: image.name,
              isInternal: true,
              media: image.uuid,
              attachedTo: {
                type: "MonitorEvent",
                id: response.data.id,
              },
            });
          });

          // Wait for all image uploads to complete before proceeding
          await Promise.all(imageUploadPromises).then(() => response);
          setRequestLoading(false);
        }
        returnToDashboard();
      }
    } catch (error) {
      setRequestLoading(false);
      setError(() => t("MonitorIsRemoved"));
      console.log(error);
    }
  }, [
    dispatch,
    service,
    monitor,
    appointment,
    navigate,
    service.images.length,
  ]);

  const handleBackButtonClicked = () => {
    goOneStepBack(navigate);

    dispatch(creatingServiceActions.resetState());
  };

  const handleClockClick = () => {
    dispatch(creatingServiceActions.resetState());
  };

  const handleLoadingChange = (loading: boolean) => {
    setRequestLoading(loading);
  };

  useEffect(() => {
    setMonitorEventId(
      monitorEvents.find(
        (event: MonitorEvent) => event.monitorId === monitor.id
      )?.id ?? ""
    );
  }, [monitorEvents]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await MonitorServiceAction.GetProducts(
          appointment?.jobs?.slice(-1).pop()?.id,
          monitor!?.id
        );
        const allProducts = [
          ...(response.data.webProducts || []),
          ...(response.data.sevdeskProducts || []),
        ];

        setProducts(allProducts);
        if (allProducts.length !== 0) {
          setDate(response.data.createdAt);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <AppLayout hideHeader>
      <BackButtonHeader
        handleBackButtonClicked={handleBackButtonClicked}
        showDocumentation={false}
      />

      <AppointmentDetails handleClockClickReset={handleClockClick} />

      <div className={styles["content-wrapper"]}>
        <ServiceInfoBox data={monitor} typeOfService="NoIssues" color="green" />

        <MultiImageUploader2
          images={service.images}
          onImageUpload={handleImageUploaded}
          onDeleteImage={handleImageDelete}
          onLoadingChange={handleLoadingChange}
        />
        <CustomCollapse products={products} date={date} readOnly={true}>
          Arbeits-, Material- und Präparateeinsatz
        </CustomCollapse>
        <CustomTextArea
          placeholder="Zusätzlicher Text"
          onTextareaChange={handleTextareaChange}
          defaultValue={service.comment}
        />
        {error && <div className={styles["error-message"]}>{error}</div>}

        <FloatingButtons>
          <CustomButton
            color="green"
            onClick={createMonitorEvent}
            loading={requestLoading}
            width={60}
          >
            {t("Confirm")}
          </CustomButton>
        </FloatingButtons>
      </div>
    </AppLayout>
  );
};
