import { useTranslation } from "react-i18next";

import { MonitorService } from "../../types/monitor";
import { CardWrapper } from "../CardWrapper/CardWrapper";
import { MonitorTypeListItem } from "../MonitorTypeListItem/MonitorTypeListItem";

import styles from "./services-overview-types.module.css";

interface Props {
  monitors: any[];
  count?: number;
}
export const MonitorsToBeChecked = ({ monitors, count }: Props) => {
  const { t } = useTranslation();

  return (
    <CardWrapper
      title={t("Monitors to be checked")}
      color="gray"
      service="maintenance"
      addInfoIcon={false}
      infestationCount={count}
    >
      {monitors?.map((monitor: MonitorService, i: number) => (
        <MonitorTypeListItem key={i} onClick={() => ({})}>
          <div className={styles["list__item"]}>
            <span className={styles["list__card--monitortype__span"]}>
              {monitor.monitorType} {monitor.isTemporary ? "T" : ""}
              {monitor.position}, {monitor.location}
            </span>
            <img src="/img/info-icon.png" />
          </div>
        </MonitorTypeListItem>
      ))}
      {monitors.length === 0 && (
        <div className={styles["empty-list"]}>
          {t("All monitors are checked")}
        </div>
      )}
    </CardWrapper>
  );
};
