import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { MonitorEventByIdResponse } from "../../api/MonitorService";
import MonitorServiceAction from "../../api/MonitorService";
import { RootState } from "../../store";
import { MinimalProduct } from "../../types/product";
import { formatServiceData } from "../../utils/formatServiseData";
import { CustomCollapse } from "../CustomCollapse/CustomCollapse";
import { CustomTextArea } from "../CustomTextArea/CustomTextArea";
import { ResourceImages } from "../ResourceImages/ResourceImages";

interface Props {
  event: MonitorEventByIdResponse | undefined;
}

export const NoInfestationDetails = ({ event }: Props) => {
  const { images } = formatServiceData(event);
  const appointment = useSelector((state: RootState) => state.appointment);
  const [products, setProducts] = useState<MinimalProduct[]>([]);
  const [date, setDate] = useState("");

  const id = event?.data?.monitorId;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        if (!id) {
          return;
        }
        const response = await MonitorServiceAction.GetProducts(
          appointment?.jobs?.slice(-1).pop()?.id,
          id
        );
        const allProducts = [
          ...(response.data.webProducts || []),
          ...(response.data.sevdeskProducts || []),
        ];

        setProducts(allProducts);
        if (allProducts.length !== 0) {
          setDate(response.data.createdAt);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div>
      <ResourceImages images={images} />
      <CustomCollapse products={products} date={date} readOnly={true}>
        Arbeits-, Material- und Präparateeinsatz
      </CustomCollapse>
      <CustomTextArea
        placeholder={t("AdditionalText")}
        defaultValue={event?.data?.comment ?? ""}
        onTextareaChange={() => {}}
        readonly
      />
    </div>
  );
};
