import { Analytics } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PropertyService from "../../../api/PropertyService";
import BarChart from "../../../components/BarChart/BarChart";
import { CustomButton } from "../../../components/CustomButton/CustomButton";
import { Filters } from "../../../components/Filters/Filters";
import { LoadingSpinner } from "../../../components/LoadingSpinner/LoadingSpinner";
import { PieChartComponent } from "../../../components/PieChart/PieChart";
import { useAnalysis } from "../../../hooks/useAnalysis";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { AppLayout } from "../../../layout/AppLayout/AppLayout";
import { RootState } from "../../../store";
import styles from "../../../styles/customer-analysis.module.css";

export const CustomerAnalysis = () => {
  const [t] = useTranslation();
  const { isDesktop } = useScreenSize();

  const [propertyAreas, setPropertyAreas] = useState<any[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [filteredPropertyAreas, setFilteredPropertyAreas] = useState<string[]>(
    []
  );
  const [filtered, setFiltered] = useState(false);

  const selectedPropertyId = useSelector(
    (state: RootState) => state.user.selectedPropertyId
  );

  useEffect(() => {
    if (selectedPropertyId) {
      PropertyService.GetProperty(selectedPropertyId).then((property) => {
        const areas = property?.data?.propertyAreas?.map((propertyArea) => ({
          id: propertyArea.id,
          name: propertyArea.label,
          map: propertyArea.map,
          monitors: propertyArea.includes.monitors,
        }));

        setPropertyAreas(areas);
      });
    }
  }, [selectedPropertyId]);

  const {
    appointmentAnalysisData,
    deficiencyAnalysisData,
    infestationAnalysisData,
    pestCounts,
    countAppointment,
    loading,
  } = useAnalysis(fromDate, toDate, filteredPropertyAreas);

  const handleOpenFilter = () => {
    setShowFilter(true);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleApplyFilter = (
    fromDate: string,
    toDate: string,
    propertyAreas: string[]
  ) => {
    setShowFilter(false);
    setFromDate(fromDate);
    setToDate(toDate);
    setFilteredPropertyAreas(propertyAreas);

    if (fromDate || toDate || propertyAreas.length) setFiltered(true);
    else setFiltered(false);
  };

  const handleResetFilter = () => {
    setFromDate("");
    setToDate("");
    setFilteredPropertyAreas([]);
    setFiltered(false);
  };

  return (
    <AppLayout>
      <div className={styles["customer-analysis"]}>
        <div className={styles["customer-analysis__heading"]}>
          <p className={styles["customer-analysis__title"]}>
            {isDesktop && <Analytics />} {t("Analysis")}
          </p>

          {showFilter ? (
            <Filters
              fromDate={fromDate ? new Date(fromDate) : null}
              toDate={toDate ? new Date(toDate) : null}
              areas={filteredPropertyAreas}
              propertyAreaOptions={propertyAreas}
              onClose={handleCloseFilter}
              onApplyFilter={handleApplyFilter}
            />
          ) : (
            <div className={styles["reset"]}>
              {filtered && (
                <p
                  className={styles["filters__reset-text"]}
                  onClick={handleResetFilter}
                >
                  {t("ResetFilter")}{" "}
                </p>
              )}
              <CustomButton
                icon={filtered ? "filterLight" : "filterDark"}
                color={filtered ? "green" : "lightGray"}
                width={48}
                onClick={handleOpenFilter}
              />
            </div>
          )}
        </div>

        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className={styles["charts"]}>
            <BarChart
              title={t("NumberOfPests")}
              subtitle={t("NOPsubtitle")}
              data={pestCounts}
            />

            <PieChartComponent
              title={t("LastServices")}
              subtitle={t("InTheSelectedPeriod")}
              data={appointmentAnalysisData}
              thicklineShown
            />

            <PieChartComponent
              title={t("Deficiency")}
              subtitle={t("DeficiencyAnalysisSubtitle")}
              data={deficiencyAnalysisData}
              thicklineShown
            />

            <PieChartComponent
              title={t("InfestationAnalysis")}
              subtitle={t("InfestationAnalysisSubtitle", { countAppointment })}
              data={infestationAnalysisData}
            />
          </div>
        )}
      </div>
    </AppLayout>
  );
};
