import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PropertyService from "../../api/PropertyService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { Deficiencies } from "../../components/Deficiencies/Deficiencies";
import { Documents } from "../../components/Documents/Documents";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { InfoTab } from "../../components/InfoTab/InfoTab";
import { LastServices } from "../../components/LastServices/LastServices";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../components/Modal/Modal";
import { PropertyAreaMonitors } from "../../components/PropertyAreaMonitors/PropertyAreaMonitors";
import { SubMenuButton } from "../../components/SubMenuButton/SubMenuButton";
import { CreateTaskIcon } from "../../icons/CreateTask/CreateTaskIcon";
import { UploadDocumentIcon } from "../../icons/UploadDocumentIcon/UploadDocumentIcon";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { appStateActions } from "../../store/app-state-slice";
import { checkIsRunning } from "../../store/clock-slice";
import { creatingMonitorActions } from "../../store/monitor-slice";
import { servicedMonitorsActions } from "../../store/serviced-monitors";
import { activeTabsActions } from "../../store/tabs-slice";
import { ROLES } from "../../store/user-slice";
import styles from "../../styles/task-insight.module.css";
import { mapPropertyAreas } from "../../utils/mapPropertyAreas";

import { ButtonType } from "./PropertyInsight";
import { SUB_MENU_BUTTONS } from "./PropertyInsight";

export const PropertyInsightViewOnly = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { propertyId } = useParams();

  const [propertyAreaMonitors, setPropertyAreaMonitors] = useState<any[]>([]);
  const [currentTab, setCurrentTab] = useState<ButtonType>("Monitors");
  const [forbiddenAccess, setForbiddenAccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobId, setJobId] = useState("");

  const appointment = useSelector((state: RootState) => state.appointment);
  const property = useSelector((state: RootState) => state.task.property);
  const currentTabState = useSelector(
    (state: RootState) => state.tabs.dashboard.tab
  );
  const documentsStep = useSelector(
    (state: RootState) => state.tabs.documents.step
  );
  const user = useSelector((state: RootState) => state.user.currentUser.user);
  const userRoles = useSelector(
    (state: RootState) => state.user.currentUser.roles
  );
  const isRunning = useSelector((state: RootState) =>
    checkIsRunning(state, appointment.id, user!.id)
  );
  const isOnline = useSelector((state: RootState) => state.online.isOnline);
  const monitorsStep = useSelector(
    (state: RootState) => state.tabs.monitors.step
  );

  // If servicedDeficiency is not null it means deficiency service is in progress
  const servicedDeficiency = useSelector(
    (state: RootState) => state.deficiencyService.deficiency
  );

  useEffect(() => {
    if (servicedDeficiency) {
      setForbiddenAccess(true);
    }
  }, [servicedDeficiency]);

  useEffect(() => {
    setCurrentTab(currentTabState);
  }, [currentTabState]);

  // Set current page in tabs slice
  useEffect(() => {
    if (appointment.id) {
      dispatch(
        activeTabsActions.setDashbaordUrl(
          `/properties/${appointment.id}/viewonly`
        )
      );
    }
  }, [appointment.id, dispatch]);

  useEffect(() => {
    if (appointment.jobs.length > 0) {
      const copiedJobs = Array.from(appointment.jobs);
      const lastJobId = copiedJobs.pop();
      setJobId(lastJobId.id);
    }
  }, [appointment.jobs]);

  const fetchPropertyAreaMonitors = useCallback(async () => {
    if (propertyId) {
      try {
        setLoading(true);
        const { data } = await PropertyService.GetProperty(propertyId);

        dispatch(appStateActions.addProperty(data));
        setPropertyAreaMonitors(mapPropertyAreas(data));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }, [propertyId, dispatch]);

  /* If user has internet connection, then fetch fresh data from backend.
  Otherwise, show data stored with redux. */
  useEffect(() => {
    if (isOnline) {
      fetchPropertyAreaMonitors();
    }
  }, [property, fetchPropertyAreaMonitors, isOnline, location.pathname]);

  const handleBackButtonClicked = () => {
    if (currentTab === "Monitors" && monitorsStep === 2) {
      dispatch(activeTabsActions.setMonitorsStep(1));
    } else if (currentTab === "Documents" && documentsStep === 1) {
      dispatch(activeTabsActions.setDashbaordUrl(""));
      dispatch(activeTabsActions.setPreviousPath("/"));
      dispatch(activeTabsActions.setProperyInsightTab("Monitors"));
      dispatch(activeTabsActions.setMonitorsStep(1));
      navigate("/");
    } else if (currentTab === "Documents" && documentsStep === 2) {
      dispatch(activeTabsActions.setDocumentsStep(1));
    } else if (currentTab === "Documents" && documentsStep === 3) {
      dispatch(activeTabsActions.setDocumentsStep(2));
    } else {
      // returnToDashboardPage
      dispatch(activeTabsActions.setDashbaordUrl(""));
      dispatch(activeTabsActions.setAppointmentsStep(2));
      // Clear monitors state
      dispatch(creatingMonitorActions.setInProgress(false));
      dispatch(creatingMonitorActions.setPropertyId(""));
      dispatch(creatingMonitorActions.resetState());

      // Reset tab
      dispatch(activeTabsActions.setProperyInsightTab("Monitors"));

      dispatch(servicedMonitorsActions.resetState());
      navigate(-1);
    }
  };

  const handleChangeTab = (tab: ButtonType) => {
    setCurrentTab(tab);
    dispatch(activeTabsActions.setMonitorsStep(1));
    dispatch(activeTabsActions.setDocumentsStep(1));
    dispatch(activeTabsActions.setPreviousPath("/viewonly"));
    dispatch(activeTabsActions.setProperyInsightTab(tab));
  };

  const forbiddenAccessHandler = () => {
    dispatch(activeTabsActions.setDashbaordUrl("/"));
    navigate("/");
  };

  const goToUploadDocument = () => {
    dispatch(activeTabsActions.setDocumentsStep(1));
    dispatch(activeTabsActions.setPreviousPath("/viewonly"));

    if (propertyId) {
      navigate(`/${propertyId}/documents/upload`);
    }
  };

  return (
    <AppLayout hideHeader={true}>
      <div id="scrollable-wrapper">
        <BackButtonHeader
          handleBackButtonClicked={handleBackButtonClicked}
          showDocumentation={false}
        />
        {documentsStep !== 3 && <AppointmentDetails />}
        <div className={styles["tabs"]}>
          {documentsStep !== 3 && (
            <div className={styles["buttons"]}>
              {SUB_MENU_BUTTONS.map((button, i) => (
                <SubMenuButton
                  currentTab={currentTab}
                  value={button}
                  onSetTab={() => handleChangeTab(button)}
                  key={i}
                />
              ))}
            </div>
          )}

          <Modal
            isOpen={forbiddenAccess}
            message={t("AnotherServiceInProgress")}
            onConfirm={forbiddenAccessHandler}
          />

          {currentTab === "Info" && <InfoTab />}

          {currentTab === "LastServices" && <LastServices />}

          {currentTab === "Documents" && <Documents />}

          {currentTab === "Monitors" && (
            <>
              {loading && <LoadingSpinner loading={loading} />}
              {!loading && (
                <div className={styles.monitors}>
                  {/* This should be refactored: fetching area monitors and stuff should be 
                    handled inside of PropertyAreaMonitors component. I changed handling of loading state 
                    since it created a bug where loading spinner was sometimes rendered twice. */}
                  <PropertyAreaMonitors
                    monitors={propertyAreaMonitors}
                    startService={() => { }}
                    isServiceStarted={isRunning}
                    jobId={jobId}
                  />
                </div>
              )}
            </>
          )}

          {currentTab === "Defects & Infestation" && (
            <div className={styles.monitors}>
              <Deficiencies />
            </div>
          )}
        </div>
        {currentTab === "Documents" ? (
          documentsStep !== 3 && (userRoles?.includes(ROLES.ADMIN) ?? false) ? (
            <FloatingButtons justify="right">
              <UploadDocumentIcon onClick={goToUploadDocument} />
            </FloatingButtons>
          ) : null
        ) : (
          <FloatingButtons justify="right">
            <CreateTaskIcon />
          </FloatingButtons>
        )}
      </div>
    </AppLayout>
  );
};
