import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { PestCount } from "../../hooks/useAnalysis";

import styles from "./bar-chart.module.css";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export interface Dataset {
  label: string;
  data: (number | null)[];
  borderWidth: number;
}

interface BarChartProps {
  title: string;
  subtitle: string;
  data: PestCount[];
}

const initialData = {
  labels: [] as string[],
  datasets: [] as Dataset[],
};

const BarChart = ({ title, subtitle, data }: BarChartProps) => {
  const [barData, setBarData] = useState(initialData);
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart<"bar"> | null>(null);
  const { t } = useTranslation();

  const translateAndFormatMonth = (monthWithYear: string): string => {
    return monthWithYear.replace(/(\w+),(\d+)/, (match, month, year) => {
      const formattedMonth =
        month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
      return `${t(formattedMonth)} ${year}`;
    });
  };
  const initializeChart = useCallback(() => {
    if (!chartRef.current || !barData.datasets.length) {
      chartInstance?.current?.destroy();
      return;
    }

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const chartCanvas = chartRef.current;

    const chart = new Chart(chartCanvas, {
      type: "bar",
      data: barData,
      options: {
        responsive: true,
        skipNull: true,
        layout: { padding: 20 },
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: false,
            external: function (context) {
              const tooltipEl = document.getElementById("custom-tooltip");

              if (!tooltipEl) {
                const div = document.createElement("div");
                div.id = "custom-tooltip";
                div.style.position = "absolute";
                div.style.backgroundColor = "#fff";
                div.style.color = "#000";
                div.style.borderRadius = "12px";
                div.style.padding = "5px 8px";
                div.style.boxShadow = "0px 4px 4px 0px #00000040";
                div.style.pointerEvents = "none";
                div.style.textAlign = "left";
                div.style.fontSize = "12px";
                div.style.fontWeight = "400";
                div.style.fontFamily = "Roboto";
                div.style.letterSpacing = "0.3";
                div.style.width = "190px";
                document.body.appendChild(div);
              }

              const tooltipModel = context.tooltip;

              if (tooltipModel.opacity === 0) {
                tooltipEl!.style.opacity = "0";
                return;
              }

              if (tooltipModel.body) {
                const { dataIndex, datasetIndex } = tooltipModel.dataPoints[0];
                const pest = data[dataIndex].pestCounts.find(
                  (pest) =>
                    pest.pest.toLowerCase() ===
                    barData.datasets[datasetIndex].label.toLowerCase()
                );

                if (pest) {
                  const tooltipContent = pest.monitors
                    .map((monitor, index) => {
                      const isLast = index === pest.monitors.length - 1;
                      return `
                      <div style="display: flex; justify-content: space-between; width: 100%; padding: 4px 0; ${
                        isLast ? "" : "border-bottom: 1px solid #CED3DC;"
                      }">
                        <span style="color: #3D5170;">${
                          monitor.referenceNumber
                        }</span>
                        <span style="color: #8493AB;">${monitor.count}</span>
                      </div>`;
                    })
                    .join("");

                  tooltipEl!.innerHTML = tooltipContent;
                }
              }

              const position = context.chart.canvas.getBoundingClientRect();

              tooltipEl!.style.opacity = "1";
              tooltipEl!.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + "px";
              tooltipEl!.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY + "px";
            },
          },
        },
        scales: {
          x: { position: "top" },
          y: {
            ticks: { display: false },
            grid: {
              offset: true,
              display: true,
              color: "#EDEFF5",
              drawTicks: false,
            },
            beginAtZero: true,
          },
        },
        maintainAspectRatio: false,
      },
    });

    chartInstance.current = chart;

    setTimeout(() => {
      const chartContainer = chartCanvas.parentElement?.parentElement;
      if (chartContainer) {
        chartContainer.scrollLeft = chartContainer.scrollWidth;
      }
    }, 10);

    return () => {
      chartInstance.current?.destroy();
    };
  }, [barData]);

  useEffect(() => {
    initializeChart();
  }, [initializeChart]);

  useEffect(() => {
    const allPests = new Set(
      data.flatMap((entry) => entry.pestCounts.map((pest) => pest.pest))
    );

    const datasets = Array.from(allPests).map((pest: string) => ({
      label: pest.charAt(0).toUpperCase() + pest.slice(1).toLowerCase(),
      data: data.map(
        (entry) => entry.pestCounts.find((p) => p.pest === pest)?.count ?? null
      ),
      borderWidth: 1,
      borderColor: "rgba(0,0,0,0)",
      backgroundColor: "#CED3DC",
      barThickness: 30,
      skipNull: true,
      borderRadius: 6,
      datalabels: {
        display: (context: any) => {
          return context.dataset.data[context.dataIndex];
        },
        labels: [
          {
            formatter: (value: any, context: any) => {
              return context.dataset.data[context.dataIndex] > 0
                ? context.dataset?.label
                : "";
            },
            align: "end",
            anchor: "start",
            rotation: -90,
            color: "#3D5170",
          },
          {
            align: "start",
            anchor: "start",
            formatter: Math.round,
            color: "#3D5170",
          },
        ],
      },
    }));

    const maxBarsInMonth = Math.max(
      ...data.map((entry) => entry.pestCounts.length)
    );

    const calculateWidth = (numBars: number) => {
      const minWidthPerBar = 50;
      const maxWidthPerBar = 200;
      return Math.max(
        numBars * minWidthPerBar,
        maxBarsInMonth * maxWidthPerBar
      );
    };

    setBarData({
      ...data,
      labels: data.map((entry) => translateAndFormatMonth(entry.month)),
      datasets: datasets,
    });

    document.documentElement.style.setProperty(
      "--barchart-min-width",
      `${calculateWidth(maxBarsInMonth)}px`
    );
  }, [data]);

  return (
    <div className={styles["barchart"]}>
      <p className={styles["barchart__title"]}>{title}</p>

      <span className={styles["barchart__subtitle"]}>{subtitle}</span>

      <div className={styles["barchart__data"]}>
        <div
          style={{
            minWidth: "var(--barchart-min-width)",
            height: "500px",
          }}
        >
          <canvas ref={chartRef} />
        </div>
      </div>
    </div>
  );
};

export default BarChart;
