import calendar from "./calendar_icon2_24px.png";

type CalendarIcon2Props = {
  color?: "light";
};

const CALENDAR_IMAGES: Record<"light", string> = {
  light: calendar,
};

export const CalendarIcon2 = ({ color = "light" }: CalendarIcon2Props) => (
  <img src={CALENDAR_IMAGES[color]} alt="Calendar icon" height={24} />
);
