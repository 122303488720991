import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PropertyService from "../../api/PropertyService";
import { useDocumentation } from "../../hooks/useDocumentation";
import { ThreeDotsIcon } from "../../icons/ThreeDotsIcon/ThreeDotsIcon";
import { RootState } from "../../store";
import { documentUploadActions } from "../../store/document-upload.slice";
import { activeTabsActions } from "../../store/tabs-slice";
import { ROLES } from "../../store/user-slice";
import { mapDocumentEdit } from "../../utils/mapDocumentEdit";
import { FloatingButtons } from "../FloatingButtons/FloatingButtons";
import { List } from "../List/List";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import Modal from "../Modal/Modal";
import { NoData } from "../NoData/NoData";

import styles from "./documents.module.css";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

export const Documents = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<any[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState<any[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numPages, setNumPages] = useState<number>();
  const propertyIdFromProperty = useSelector(
    (state: RootState) => state.property.id
  );
  const propertyIdFromAppointment = useSelector(
    (state: RootState) => state.appointment.propertyId
  );
  const isViewOnly = useSelector((state: RootState) => state.property.viewOnly);
  const propertyId = isViewOnly
    ? propertyIdFromProperty
    : propertyIdFromAppointment;

  const documentsStep = useSelector(
    (state: RootState) => state.tabs.documents.step
  );

  const chosenDocumentType = useSelector(
    (state: RootState) => state.tabs.documents.chosenDocumentType
  );

  const selectedDocument = useSelector(
    (state: RootState) => state.tabs.documents.selectedDocument
  );

  const userRoles = useSelector(
    (state: RootState) => state.user.currentUser.roles
  );

  const { fetchDocumentation } = useDocumentation();

  const documentItems = [
    { id: "1", name: "Gefährdungsbeurteilung", value: "riskAssessment" },
    { id: "2", name: "Allgemein", value: "general" },
  ];

  useEffect(() => {
    setLoading(true);

    PropertyService.GetProperty(propertyId)
      .then((result) => {
        const documentsWithNames = result.includes.documents.map(
          (doc: any) => ({
            ...doc,
            name: doc.fileName,
          })
        );

        setDocuments(documentsWithNames);
        setFilteredDocuments(documentsWithNames);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [propertyId]);

  useEffect(() => {
    if (documentsStep === 1) {
      setFilteredDocuments(documents);
    } else if (documentsStep === 2 && chosenDocumentType) {
      const filtered = documents
        .filter((doc) => doc.category === chosenDocumentType)
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      setFilteredDocuments(filtered);
    }
  }, [documentsStep, chosenDocumentType, documents]);

  const onSelectType = (type: any) => {
    dispatch(activeTabsActions.setChosenDocumentType(type.value));
    dispatch(activeTabsActions.setDocumentsStep(2));
  };

  const onSelectDocument = (document: any) => {
    dispatch(activeTabsActions.setSelectedDocument(document));
    dispatch(activeTabsActions.setDocumentsStep(3));
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handleThreeDotsIconClick = () => {
    if (userRoles?.includes(ROLES.ADMIN)) {
      setIsModalOpen(true);
    } else {
      fetchDocumentation(selectedDocument.url);
      dispatch(activeTabsActions.setDocumentsStep(2));
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <>
          {documentsStep === 1 && (
            <>
              {documents.length === 0 ? (
                <NoData>{t("NoDocumentsAvailable")}</NoData>
              ) : (
                <List
                  data={documentItems.filter((item) =>
                    documents.some((doc) => doc.category === item.value)
                  )}
                  onItemSelected={(documentType: any) => {
                    onSelectType(documentType);
                  }}
                  hideShadow
                />
              )}
            </>
          )}

          {documentsStep === 2 && (
            <>
              {filteredDocuments.length === 0 ? (
                <NoData>{t("NoDocumentsAvailable")}</NoData>
              ) : (
                <List
                  data={filteredDocuments}
                  onItemSelected={(document: any) => {
                    onSelectDocument(document);
                  }}
                  hideShadow
                />
              )}
            </>
          )}

          {documentsStep === 3 && selectedDocument && (
            <div>
              {isModalOpen && (
                <Modal
                  isOpen={isModalOpen}
                  message={t("HowToProceed")}
                  backButtonShow={closeModal}
                  onCancel={() => {
                    dispatch(
                      documentUploadActions.setDocumentForEdit(
                        mapDocumentEdit(selectedDocument)
                      )
                    );

                    if (propertyId) {
                      navigate(`/${propertyId}/documents/upload`, {
                        state: { documentId: selectedDocument.id },
                      });
                    }
                  }}
                  onConfirm={() => {
                    fetchDocumentation(selectedDocument.url);

                    dispatch(activeTabsActions.setDocumentsStep(2));
                  }}
                  confirmText={"Download"}
                  cancelText={"Edit"}
                  closeButtonColor="#3D5170"
                />
              )}
              <h2 className={styles["title"]}>{selectedDocument.name}</h2>
              <div className={styles["pdf-preview"]}>
                <Document
                  file={selectedDocument.url}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={<LoadingSpinner loading />}
                >
                  {[...Array(numPages)].map((_, index) => (
                    <Page
                      key={index}
                      pageNumber={index + 1}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      width={Math.min(window.innerWidth * 0.9, 800)}
                    />
                  ))}
                </Document>
              </div>

              <FloatingButtons justify="right">
                <ThreeDotsIcon onClick={handleThreeDotsIconClick} />
              </FloatingButtons>
            </div>
          )}
        </>
      )}
    </div>
  );
};
