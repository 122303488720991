import { PDFDownloadLink, UsePDFInstance } from "@react-pdf/renderer";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import AppointmentService from "../../api/AppointmentService";
import JobService, { JobQueryParams } from "../../api/JobService";
import { PDFDocumentForm } from "../../components/PDFDocument/PDFDocumentForm";
import { useDocumentation } from "../../hooks/useDocumentation";
import { RootState } from "../../store";
import { creatingDocumentationActions } from "../../store/documentation-slice";
import { calculateTotalTime } from "../../utils/calculateTotalTime";
import { formatWorkloadTime } from "../../utils/formatWorkloadTime";

const PDFGenerate = () => {
  const { appointmentId } = useParams();
  const dispatch = useDispatch();

  const [webProducts, setWebProducts] = useState<any>(null);
  const [sevdeskProducts, setSevdeskProducts] = useState<any>(null);

  const documentation = useSelector((state: RootState) => state.documentation);
  const { savePDF, updatePDF } = useDocumentation();

  const fetchGroupedEventsPerPropertyAreaByJob = async (jobId: string) => {
    if (jobId) {
      try {
        const { data } =
          await JobService.GetGroupedEventsPerPropertyAreaByJob(jobId);

        dispatch(creatingDocumentationActions.setPropertyAreaEvents(data));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchGroupedMonitorsPerPropertyAreaByAppointment = async () => {
    if (appointmentId) {
      try {
        const { data } =
          await AppointmentService.GetAdjustedMonitors(appointmentId);

        dispatch(
          creatingDocumentationActions.setPropertyAreaAdjustedMonitors(data)
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchGroupedMaterialsByJob = async (jobId: string) => {
    if (jobId) {
      try {
        const { data } = await JobService.GetGroupedMaterialsByJob(jobId);

        if (data.webProducts) {
          setWebProducts(
            data.webProducts.map((p: any) => ({
              id: p.item.productId,
              name: p.item.product.name,
              quantity: p.quantity,
            }))
          );
        }

        if (data.sevdeskProducts) {
          setSevdeskProducts(
            data.sevdeskProducts.map((p: any) => ({
              id: p.item.id,
              name: p.item.name,
              quantity: p.quantity,
            }))
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getPDFData = async () => {
    dispatch(
      creatingDocumentationActions.setAttachedTo({
        type: "Appointment",
        id: appointmentId!,
      })
    );

    const { data } = await AppointmentService.GetAppointment(appointmentId!);
    const appointmentSubsetData = {
      customerName: data.additionalData.customer.name,
      address: data.additionalData.property.address,
      technicians: data.technicians.map(({ firstName, lastName }) => ({
        firstName,
        lastName,
      })),
      typeOfAppointment: data.typeOfAppointment,
    };

    dispatch(
      creatingDocumentationActions.setAppointmentSubset(appointmentSubsetData)
    );

    const { totalTime, firstEvent, lastEvent } = await calculateTotalTime(
      data.id
    );
    dispatch(
      creatingDocumentationActions.setTotalTimeWorkload(
        formatWorkloadTime(totalTime)
      )
    );

    const jobId = data?.jobs?.slice(-1).pop().id;

    if (firstEvent && lastEvent) {
      const newQueryParams: JobQueryParams = {
        "filter[sinceDate]": moment(firstEvent.createdAt).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        "filter[untilDate]": moment(lastEvent.createdAt).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
      };

      try {
        const { data } =
          await JobService.GetGroupedDeficienciesPerPropertyAreaByJob(
            jobId,
            newQueryParams
          );

        dispatch(
          creatingDocumentationActions.setPropertyAreaDeficiencies(data)
        );
      } catch (error) {
        console.error("Error fetching grouped deficiencies:", error);
      }

      await fetchGroupedEventsPerPropertyAreaByJob(jobId);
      await fetchGroupedMonitorsPerPropertyAreaByAppointment();
      await fetchGroupedMaterialsByJob(jobId);

      dispatch(creatingDocumentationActions.setRemark(data.comment ?? ""));
      dispatch(
        creatingDocumentationActions.setDate(
          moment(data.createdAt).format("DD. MMMM YYYY")
        )
      );
    }
  };

  const saveReceipt = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | null,
    instance: UsePDFInstance
  ) => {
    if (instance.loading || !instance.url || !instance.blob) {
      return;
    }

    if (!documentation.existingReceipt) {
      savePDF(instance, documentation.attachedTo);
    } else {
      updatePDF(instance);
    }
  };

  useEffect(() => {
    if (appointmentId) {
      getPDFData();
    }
  }, [appointmentId]);

  useEffect(() => {
    const combinedProducts = [
      ...(webProducts || []),
      ...(sevdeskProducts || []),
    ];

    dispatch(creatingDocumentationActions.setProducts(combinedProducts));
  }, [webProducts, sevdeskProducts, dispatch]);

  return (
    <PDFDownloadLink
      document={<PDFDocumentForm documentation={documentation} />}
      onClick={saveReceipt}
    >
      Download PDF
    </PDFDownloadLink>
  );
};

export default PDFGenerate;
