import { createSlice } from "@reduxjs/toolkit";

import { DocumentUploadType } from "../types/document";
import { ImageProps } from "../types/image";

import { ROLES } from "./user-slice";

export type DocumentUploadSliceState = {
  fileName: string;
  media: ImageProps;
  documentUploadType: "riskAssessment" | "general";
  allowedRoles: string[];
};

const initialState: DocumentUploadSliceState = {
  fileName: "",
  media: {
    id: "",
    uuid: "",
    name: "",
    preview_url: "",
    size: 0,
    mime_type: "",
    extension: "",
    thumbnails: [],
    originalUrl: "",
  },
  documentUploadType: "riskAssessment",
  allowedRoles: [ROLES.ADMIN],
};

export const documentUploadSlice = createSlice({
  name: "document-upload",
  initialState: initialState,
  reducers: {
    setFileName(state, action: { payload: string }) {
      state.fileName = action.payload;
    },
    setDocumentUploadType(state, action: { payload: DocumentUploadType }) {
      state.documentUploadType = action.payload;
    },
    setAllowedRoles(state, action: { payload: string[] }) {
      const roles = new Set(action.payload);
      roles.add(ROLES.ADMIN);
      state.allowedRoles = Array.from(roles);
    },
    setMedia(state, action: { payload: ImageProps }) {
      state.media = action.payload;
    },
    setDocumentForEdit(state, action: { payload: DocumentUploadSliceState }) {
      return { ...state, ...action.payload };
    },
    resetState(state: DocumentUploadSliceState) {
      return { ...initialState };
    },
  },
});

export const documentUploadActions = documentUploadSlice.actions;
