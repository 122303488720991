import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type BasicTabPages = {
  page: "list" | "details";
  data: any;
};

export type ProperyInsightTabs =
  | "Info"
  | "LastServices"
  | "Monitors"
  | "Documents"
  | "Defects & Infestation";

export type TabsSliceState = {
  dashboard: {
    url: string;
    tab: ProperyInsightTabs;
  };
  time: BasicTabPages;
  appointments: {
    step: 1 | 2 | 3;
  };
  calendar: BasicTabPages;
  settings: BasicTabPages;
  monitors: {
    step: 1 | 2;
  };
  issuesTabs: "baitEaten" | "animalFound";
  deficiencies: {
    step: 1 | 2 | 3;
  };
  services: {
    step: 1 | 2;
  };
  adjustment: {
    step: 1 | 2;
  };
  previousPath: string;
  documents: {
    step: 1 | 2 | 3;
    chosenDocumentType: string;
    selectedDocument: any;
  };
};

const initialState: TabsSliceState = {
  dashboard: {
    url: "",
    tab: "Monitors",
  },
  time: {
    page: "list",
    data: "",
  },
  appointments: {
    step: 1,
  },
  calendar: {
    page: "list",
    data: "",
  },
  settings: {
    page: "list",
    data: "",
  },
  monitors: {
    step: 1,
  },
  issuesTabs: "baitEaten",
  deficiencies: {
    step: 1,
  },
  services: {
    step: 1,
  },
  adjustment: {
    step: 1,
  },
  previousPath: "",
  documents: {
    step: 1,
    chosenDocumentType: "riskAssessment",
    selectedDocument: null,
  },
};

export const activeTabsSlice = createSlice({
  name: "tabs",
  initialState: initialState,
  reducers: {
    setDashbaordUrl(state, action: PayloadAction<string>) {
      state.dashboard.url = action.payload;
    },
    setProperyInsightTab(state, action: PayloadAction<ProperyInsightTabs>) {
      state.dashboard.tab = action.payload;
    },
    setAppointmentsStep(state, action: PayloadAction<1 | 2 | 3>) {
      state.appointments.step = action.payload;
    },
    setCalendarsStep(
      state,
      action: { payload: { page: "list" | "details"; data: any } }
    ) {
      state.calendar.page = action.payload.page;
      state.calendar.data = action.payload.data;
    },
    setMonitorsStep(state, action: PayloadAction<1 | 2>) {
      state.monitors.step = action.payload;
    },
    setDeficienciesStep(state, action: PayloadAction<1 | 2 | 3>) {
      state.deficiencies.step = action.payload;
    },
    setServicesStep(state, action: PayloadAction<1 | 2>) {
      state.services.step = action.payload;
    },
    setAdjustmentStep(state, action: PayloadAction<1 | 2>) {
      state.adjustment.step = action.payload;
    },
    setDocumentsStep(state, action: PayloadAction<1 | 2 | 3>) {
      state.documents.step = action.payload;
    },
    setChosenDocumentType(state, action) {
      state.documents.chosenDocumentType = action.payload;
    },
    setSelectedDocument(state, action) {
      state.documents.selectedDocument = action.payload;
    },
    setIssuesTab(state, action: PayloadAction<"baitEaten" | "animalFound">) {
      state.issuesTabs = action.payload;
    },
    setPreviousPath(state, action: PayloadAction<string>) {
      state.previousPath = action.payload;
    },
  },
});

export const activeTabsActions = activeTabsSlice.actions;
