import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Error } from "../api/ApiService";
import AppointmentService, {
  AppointmentQueryParams,
} from "../api/AppointmentService";
import { RootState } from "../store";
import {
  AppointmentsSliceState,
  appointmentsActions,
} from "../store/appointments-slice";
import { customerDeficienciesActions } from "../store/customer-deficiencies-slice";
import { Appointment } from "../types/appointment";
import { Deficiency } from "../types/deficiencies";
import { Document } from "../types/document";
import { Meta } from "../types/response";
const TODAY = moment().startOf("day");
const TOMORROW = moment().add(1, "days").startOf("day");

export const useAppointment = (
  queryParams?: AppointmentQueryParams,
  groupAndDispatch: boolean = false,
  isInfiniteScroll: boolean = false
) => {
  const dispatch = useDispatch();

  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [appointmentsMeta, setAppointmentsMeta] = useState<Meta>();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const selectedPropertyId = useSelector(
    (state: RootState) => state.user.selectedPropertyId
  );

  const page = queryParams?.["page[number]"] ?? 1;

  const groupAppointmentsByDate = (appointments: Appointment[]) => {
    return appointments.reduce(
      (groups: AppointmentsSliceState, appointment: Appointment) => {
        const plannedStart = moment(appointment.plannedStart);

        if (plannedStart.isSame(TOMORROW, "day")) {
          groups.tomorrow.push(appointment);
        } else if (plannedStart.isSame(TODAY, "day")) {
          groups.today.push(appointment);
        } else {
          groups.future.push(appointment);
        }

        Object.keys(groups).map((key) => {
          return groups[key as keyof typeof groups].sort(function (
            a: any,
            b: any
          ) {
            return (
              moment(a.plannedStart).valueOf() -
              moment(b.plannedStart).valueOf()
            );
          });
        });
        return groups;
      },
      { today: [], tomorrow: [], future: [] }
    );
  };

  const fetchAppointments = useCallback(
    async (selectedPropertyId?: string) => {
      try {
        setLoading(true);

        const appointments = await AppointmentService.GetAppointments({
          ...(queryParams && { ...queryParams }),
          ...(selectedPropertyId && {
            "filter[propertyId]": selectedPropertyId,
          }),
        });

        //find pdf document for every appointment and attach it to the object as a document
        const _appointments: Appointment[] = appointments.data.map(
          (appointment: Appointment) => ({
            ...appointment,
            document: appointments.includes.documents?.find(
              ({ attachedTo }: Document) =>
                attachedTo.type === "Appointment" &&
                attachedTo.id === appointment.id
            ),
            incident: appointments.includes.incidents?.find(
              (incident: Deficiency) =>
                incident.id === appointment.incidents[0]?.id
            ),
          })
        );

        if (isInfiniteScroll) {
          setAppointments((prevState) => [...prevState, ..._appointments]); //If infinite scroll is used, append new data to prevState
        } else {
          setAppointments(_appointments);
        }
        setAppointmentsMeta(appointments.meta);

        if (groupAndDispatch) {
          const groupedAppointments = groupAppointmentsByDate(_appointments);
          dispatch(appointmentsActions.setAppointments(groupedAppointments));
        }

        if (_appointments.length > 0) {
          const propertyId = _appointments[0].propertyId;
          dispatch(customerDeficienciesActions.setPropertytId(propertyId));
        }
      } catch (error) {
        const err = error as Error;
        setError(err);
      }

      setLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch, queryParams, isInfiniteScroll]
  );

  useEffect(() => {
    if (selectedPropertyId) {
      setAppointments([]);
      fetchAppointments(selectedPropertyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPropertyId]);

  useEffect(() => {
    if (page) {
      fetchAppointments(selectedPropertyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return { appointments, setAppointments, fetchAppointments, appointmentsMeta, error, loading };
};
