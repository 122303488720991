import moment from "moment";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import AppointmentService from "../api/AppointmentService";
import BackEndService from "../api/BackEndService";
import DeficiencyService, {
  CreateDeficiencyPayload,
} from "../api/DeficiencyService";
import JobService from "../api/JobService";
import { createCombinedDateTime } from "../services/DateTimeService";
import { RootState } from "../store";
import { creatingDeficiencyActions } from "../store/deficiencies-slice";
import { activeTabsActions } from "../store/tabs-slice";
import { techniciansSliceActions } from "../store/technicians-slice";

export const useCreateDeficiency = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const appointment = useSelector((state: RootState) => state.appointment);
  const deficiency = useSelector((state: RootState) => state.deficiency);
  const technicians = useSelector((state: RootState) => state.technicians);

  const handleCreateDeficiency = useCallback(() => {
    setLoading(true);
    const requiredActions = [];
    requiredActions.push(deficiency.recommendation);

    /* If responsibility is set to customer then take dueDate as a dueDate,
    otherwise make dueDate from appointment startDate and stratTime 
    so we don't need to make additional request to fetch appointment data */
    const dueDate =
      deficiency.responsibility === "customer"
        ? moment.utc(deficiency.dueDate, "DD.MM.YYYY").toISOString()
        : createCombinedDateTime(
            deficiency.plannedStart.date,
            deficiency.plannedStart.time
          );

    const deficiencyRequest: CreateDeficiencyPayload = {
      customer_id: appointment.customerId,
      state: "open",
      responsibility: deficiency.responsibility,
      comment: deficiency.comment,
      property_id: deficiency.propertyId,
      property_area_id: deficiency.propertyAreaId,
      location: deficiency.location,
      required_actions: requiredActions,
      deficiency_type: deficiency.deficiencyType.key,
      deficiency_sub_type: deficiency.deficiencySubtype.key,
      dueDate,
      map_position: {
        map_version_id: uuidv4(),
        map_point: deficiency.deficiencyPosition,
      },
    };

    const appointmentRequest = {
      customerId: deficiency.customerId,
      propertyId: deficiency.propertyId,
      plannedStart: createCombinedDateTime(
        deficiency.plannedStart.date,
        deficiency.plannedStart.time
      ),
      typeOfAppointment: "auftrag",
      additionalData: appointment.additionalData,
      comment: deficiency.comment,
      technicians: technicians.map((technician) => technician.id),
    };

    DeficiencyService.CreateDeficiency(deficiencyRequest)
      .then((deficiencyResult) => {
        // Upload images and return a promise array
        const imageUploadPromises = deficiency.images.map((image) => {
          return BackEndService.AddDocument({
            documentType: "userUpload",
            fileName: image.name,
            isInternal: true,
            media: image.uuid,
            attachedTo: {
              type: "Incident",
              id: deficiencyResult.data.id, // Assuming this is how you get the new deficiency ID
            },
          });
        });

        // Wait for all image uploads to complete before proceeding
        return Promise.all(imageUploadPromises).then(() => deficiencyResult);
      })
      .then((deficiencyResult) => {
        if (deficiencyResult.data.responsibility === "exterminator") {
          return AppointmentService.CreateAppointment({
            ...appointmentRequest,
            incidents: [deficiencyResult.data.id],
          });
        }
      })
      .then((appointment) => {
        if (appointment) {
          return JobService.AddJob({
            jobState: "scheduled",
            appointmentId: appointment.data.id,
            customerId: appointmentRequest.customerId,
            propertyId: appointmentRequest.propertyId,
            scheduledDate: appointmentRequest.plannedStart,
          });
        }
      })
      .then(() => {
        dispatch(creatingDeficiencyActions.resetState());
        dispatch(techniciansSliceActions.resetState());
        dispatch(activeTabsActions.setDeficienciesStep(1));

        // Set active tab
        dispatch(
          activeTabsActions.setProperyInsightTab("Defects & Infestation")
        );

        navigate(`/properties/${appointment.id}/show`);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [
    appointment.additionalData,
    appointment.customerId,
    appointment.id,
    deficiency.comment,
    deficiency.customerId,
    deficiency.deficiencySubtype.key,
    deficiency.deficiencyType.key,
    deficiency.dueDate,
    deficiency.images,
    deficiency.location,
    deficiency.plannedStart.date,
    deficiency.plannedStart.time,
    deficiency.propertyAreaId,
    deficiency.propertyId,
    deficiency.recommendation,
    deficiency.responsibility,
    dispatch,
    navigate,
    technicians,
    setLoading,
  ]);

  return {
    handleCreateDeficiency,
    loading,
  };
};
