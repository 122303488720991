import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import PropertyService from "../../api/PropertyService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import PinCropMap from "../../components/PinCropMap/PinCropMap";
import { ServiceInfoBox } from "../../components/ServiceInfoBox/ServiceInfoBox";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import styles from "../../styles/monitor-event-detail-view.module.css";
import { Document } from "../../types/document";

export const MonitorAdjustmentDetailView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const location = useLocation();
  const { monitor } = location.state || {};
  const [map, setMap] = useState<Document>();

  const originalVersion = monitor.versions?.find(
    (version: any) => version.isOriginal === 1
  );

  const changedVersion =
    monitor.versions?.find((version: any) => version.isOriginal === 0) ??
    monitor;

  useEffect(() => {
    PropertyService.GetPropertyArea(monitor.propertyAreaId)
      .then((propertyArea: any) => {
        setMap(propertyArea?.data.map);
      })
      .catch((error) => console.error(error));
  }, [monitor.propertyAreaId, setMap]);

  const handleBackButtonClicked = () => {
    navigate(-1);
  };

  const monitorPinAbbr = {
    name: monitor.monitorType.prefix,
    number:
      changedVersion.position != null
        ? (monitor.isTemporary ? "T" : "") + changedVersion.position
        : "001",
  };

  return (
    <AppLayout hideHeader>
      <BackButtonHeader handleBackButtonClicked={handleBackButtonClicked} />
      <AppointmentDetails />

      <div className={styles["content-wrapper"]}>
        <ServiceInfoBox
          data={{
            id: monitor.id,
            monitorType: monitor.monitorType.label,
            position: originalVersion?.position || changedVersion?.position,
            location: originalVersion?.location || changedVersion?.location,
            isActive: monitor.isActive,
            monitorTypeId: monitor.monitorTypeId,
            isTemporary: monitor.isTemporary,
            x: monitor.x,
            y: monitor.y,
          }}
          typeOfService={"Adjustment"}
          color={"blue"}
          hideCheck
        />

        {changedVersion.action === "update" && (
          <>
            <CustomInput
              label={t("NewMonitorNumber")}
              placeholder="No monitor number"
              onBlur={() => {}}
              initialValue={changedVersion.position}
              hideTick
              readonly
            />

            <div className={styles["image"]}>
              {map && (
                <PinCropMap
                  map={map}
                  crop={changedVersion.mapPosition?.mapPoint ?? { x: 1, y: 1 }}
                  onCropChange={() => {}}
                  monitorPinAbbr={monitorPinAbbr}
                  isDraggable={false}
                  zoom={false}
                />
              )}
            </div>

            <CustomInput
              label={t("NewLocationInfo")}
              placeholder="No new location"
              onBlur={() => {}}
              initialValue={changedVersion.location}
              hideTick
              readonly
            />
          </>
        )}

        {changedVersion.action === "delete" && (
          <span className={styles["text"]}>
            Dieser Monitor ist nicht mehr verfügbar.
          </span>
        )}

        <CustomTextArea
          placeholder={t("MovingMonitorReason")}
          defaultValue={changedVersion.reason ?? ""}
          onTextareaChange={() => {}}
          readonly
        />
      </div>
      <FloatingButtons>
        <CustomButton
          color="gray"
          icon="leftarrow"
          iconPosition="left"
          width={70}
          onClick={handleBackButtonClicked}
        >
          {t("Back to overview")}
        </CustomButton>
      </FloatingButtons>
    </AppLayout>
  );
};
