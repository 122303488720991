import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setAppointment } from "../store/appointment-slice";
import { propertyActions } from "../store/property-slice";
import { activeTabsActions } from "../store/tabs-slice";
import { Appointment } from "../types/appointment";

export const useAppointmentClicked = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAppointmentClicked = (appointment: Appointment) => {
    dispatch(setAppointment(appointment));
    dispatch(propertyActions.resetState());
    dispatch(activeTabsActions.setProperyInsightTab("Monitors"));
    const isDeficiency = appointment.incidents.length > 0;

    if (isDeficiency) {
      const deficiencyId = appointment.incidents[0].id;
      navigate(`/deficiencies/${deficiencyId}/show`);
      dispatch(activeTabsActions.setPreviousPath("/"));
    } else {
      navigate(`/properties/${appointment.id}/show`);
    }
  };

  return { handleAppointmentClicked };
};
