import { format } from "date-fns";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon2 } from "../../icons/CalendarIcon2/CalendarIcon2";
import { FilterIcon } from "../../icons/FilterIcon/FilterIcon";
import { CustomButton } from "../CustomButton/CustomButton";
import { FloatingButtons } from "../FloatingButtons/FloatingButtons";
import { SubMenuButton } from "../SubMenuButton/SubMenuButton";

import styles from "./filters.module.css";

type FiltersProps = {
  fromDate: Date | null;
  toDate: Date | null;
  areas: string[];
  propertyAreaOptions: any[];
  onClose: () => void;
  onApplyFilter: (
    fromDate: string,
    toDate: string,
    propertyAreas: string[]
  ) => void;
};

export const Filters = ({
  fromDate,
  toDate,
  areas,
  propertyAreaOptions,
  onClose,
  onApplyFilter,
}: FiltersProps) => {
  const { t } = useTranslation();

  const [localFromDate, setLocalFromDate] = useState<Date | null>(fromDate);
  const [localToDate, setLocalToDate] = useState<Date | null>(toDate);
  //   const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useState<
  //     string[]
  //   >([]);
  const [selectedAreas, setSelectedAreas] = useState<string[]>(areas);

  //   const appointmentTypes = [
  //     { id: "1", name: "Monitoring", value: "monitoring" },
  //     { id: "2", name: "Mangelauftrag", value: "mangelauftrag" },
  //     { id: "3", name: "Bekämpfung", value: "auftrag" },
  //   ];

  const handleResetFilter = () => {
    setLocalFromDate(null);
    setLocalToDate(null);
    setSelectedAreas([]);
    // setSelectedAppointmentTypes([]);
  };

  const handleApplyFilter = () => {
    const formattedFromDate = localFromDate
      ? format(localFromDate, "yyyy-MM-dd")
      : "";
    const formattedToDate = localToDate
      ? format(localToDate, "yyyy-MM-dd")
      : "";

    onApplyFilter(formattedFromDate, formattedToDate, selectedAreas);

    onClose();
  };

  //   const onSelectType = (type: any) => {
  //     setSelectedAppointmentTypes((prev) =>
  //       prev.includes(type.value)
  //         ? prev.filter((item) => item !== type.value)
  //         : [...prev, type.value]
  //     );
  //   };

  const onSelectArea = (areaId: string) => {
    setSelectedAreas((prev) =>
      prev.includes(areaId)
        ? prev.filter((id) => id !== areaId)
        : [...prev, areaId]
    );
  };

  return (
    <div className={styles["filters"]}>
      <div className={styles["filters__header"]}>
        <p className={styles["filters__title"]}>
          <FilterIcon color="dark" /> Filter
        </p>

        <span
          onClick={handleResetFilter}
          className={styles["filters__reset-text"]}
        >
          {t("ResetFilter")}
        </span>
      </div>

      <span className={styles["filters__type"]}>{t("TimePeriod")}</span>
      <div className={styles["filter-container"]}>
        <div className={styles["filter"]}>
          <label className={styles["filter-date__label"]}>{t("From")}</label>
          <div className={styles["date-container"]}>
            <DatePicker
              selected={localFromDate || undefined}
              onChange={(date: Date | null) => setLocalFromDate(date)}
              maxDate={localToDate || undefined}
              dateFormat="dd/MM/yyyy"
              className={styles["custom-date-picker"]}
              placeholderText="dd/mm/yyyy"
            />
            <CalendarIcon2 />
          </div>
        </div>

        <div className={styles["filter"]}>
          <label className={styles["filter-date__label"]}>{t("To")}</label>
          <div className={styles["date-container"]}>
            <DatePicker
              selected={localToDate || undefined}
              onChange={(date: Date | null) => setLocalToDate(date)}
              minDate={localFromDate || undefined}
              dateFormat="dd/MM/yyyy"
              className={styles["custom-date-picker"]}
              placeholderText="dd/mm/yyyy"
            />
            <CalendarIcon2 />
          </div>
        </div>
      </div>

      {/* <span className={styles["filters__type"]}>{t("LastServices")}</span> */}
      <div className={styles["filter-container"]}>
        {/* {appointmentTypes.length > 0 &&
          appointmentTypes.map((button, i) => (
            <SubMenuButton
              currentTab={appointmentTypes
                .filter((type) => selectedAppointmentTypes.includes(type.value))
                .map((type) => type.name)}
              value={button.name}
              onSetTab={() => onSelectType(button)}
              key={i}
              filter
              multiSelect
            />
          ))} */}
      </div>

      <span className={styles["filters__type"]}>{t("Floors")}</span>
      <div className={styles["filter-container"]}>
        <div>
          {propertyAreaOptions.length > 0 &&
            propertyAreaOptions.map((area, i) => (
              <SubMenuButton
                currentTab={propertyAreaOptions
                  .filter((type) => selectedAreas.includes(type.id))
                  .map((type) => type.name)}
                value={area.name}
                onSetTab={() => onSelectArea(area.id)}
                key={i}
                filter
                multiSelect
              />
            ))}
        </div>
      </div>

      {/* <span className={styles["filters__type"]}>{t("MonitorTypes")}</span> */}
      <div className={styles["filter-container"]}></div>

      <FloatingButtons>
        <CustomButton color="green" onClick={handleApplyFilter} width={60}>
          {t("Confirm")}
        </CustomButton>
      </FloatingButtons>
    </div>
  );
};
