import { t } from "i18next";

import { ButtonType as CustomerButtonType } from "../../pages/Customer/Dashboard/Dashboard";
import { ButtonType } from "../../pages/Dashboard/PropertyInsight";

import styles from "./sub-menu-button.module.css";

type SubMenuButtonProps = {
  currentTab: ButtonType | CustomerButtonType | string | string[];
  value: string;
  onSetTab: Function;
  filter?: boolean;
  multiSelect?: boolean;
};

export const SubMenuButton = ({
  onSetTab,
  currentTab,
  value,
  filter = false,
  multiSelect = false,
}: SubMenuButtonProps) => {
  const isSelected = Array.isArray(currentTab)
    ? currentTab.includes(value)
    : currentTab === value;

  return (
    <button
      onClick={() => onSetTab(value, multiSelect)}
      className={`${styles["low-button"]} ${
        filter && styles["low-button-white"]
      } ${isSelected ? styles["low-button-clicked"] : ""}
    ${isSelected && filter ? styles["low-button-clicked-filter"] : ""}`}
    >
      {t(value)}
    </button>
  );
};
