import moment from "moment";
import { MouseEventHandler, ReactNode, useEffect, useState } from "react";

import { PlusIcon } from "../../icons/PlusIcon/PlusIcon";
import { WrenchIcon } from "../../icons/WrenchIcon/WrenchIcon";
import { MinimalProduct } from "../../types/product";
import { MaterialListItem } from "../MaterialListItem/MaterialListItem";

import styles from "./custom-collapse.module.css";

type CustomInputProps = {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement | HTMLLabelElement>;
  products: MinimalProduct[];
  collapsedByDefault?: boolean;
  date?: string;
  readOnly?: boolean;
};

export const CustomCollapse = ({
  children,
  onClick,
  products,
  collapsedByDefault = true,
  date,
  readOnly,
}: CustomInputProps) => {
  const [collapsed, setCollapsed] = useState(collapsedByDefault);

  const filteredProducts = products.filter((product) => product.quantity > 0);
  const areProductsSelected = filteredProducts.length > 0;

  useEffect(() => {
    if (areProductsSelected) {
      setCollapsed(false);
    }
  }, [areProductsSelected]);

  return (
    <>
      <div
        className={`${styles["collapse__button"]} ${
          areProductsSelected && styles["collapse__button--active"]
        }`}
      >
        <label
          className={`${styles["collapse__button__label"]} ${
            date && styles["date"]
          }`}
          onClick={onClick}
        >
          {children}
          {date && (
            <div className={styles["additional-text"]}>
              Hinzugefügt am: {moment(date).format("DD.MM.YYYY")}
            </div>
          )}
        </label>

        <div className={styles["icons"]} onClick={onClick}>
          <span>
            <PlusIcon />
          </span>
          <WrenchIcon color="dark"></WrenchIcon>
        </div>
      </div>

      <ul
        className={`${styles["collapse__list"]} ${
          !collapsed ? styles["collapse__list--show"] : ""
        } ${areProductsSelected && styles["collapse__list--show--active"]}`}
      >
        {!areProductsSelected && (
          <li className={styles["collapse__list--empty"]}>
            Kein Material verwendet
          </li>
        )}

        {areProductsSelected &&
          filteredProducts.map((product) => (
            <MaterialListItem
              key={product.id}
              product={product}
              readOnly={readOnly}
            />
          ))}
      </ul>
    </>
  );
};
