import { Close, Search } from "@mui/icons-material";
import { Action } from "@reduxjs/toolkit";
import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import UserService from "../../api/clockodo/UserService";
import PullToRefresh from "../../components/PullToRefresh/PullToRefresh";
import WorkerStatus from "../../components/WorkerStatus/WorkerStatus";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { workersActions } from "../../store/workers-slice";
import styles from "../../styles/workers-list.module.css";

const WorkersList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const activeUsers = useSelector((state: RootState) => state.workers.active);
  const absentUsers = useSelector((state: RootState) => state.workers.absent);
  const notCheckedInUsers = useSelector(
    (state: RootState) => state.workers.notCheckedIn
  );

  useEffect(() => {
    fetchUsersData(dispatch);
  }, []);

  // Create a function to fetch the users data
  const fetchUsersData = async (dispatch: Dispatch<Action>) => {
    try {
      const [activeUsers, absentUsers, notCheckedInUsers] = await Promise.all([
        UserService.GetActiveUsers(),
        UserService.GetAbsentUsers(),
        UserService.GetNotCheckedIn(),
      ]);

      const activeUsersData = activeUsers.data.entries.map((entry) => ({
        id: entry.usersId,
        usersName: entry.usersName,
        initials: entry.usersName.substring(0, 2).toUpperCase(),
        text: "At Work",
        status: "working",
      }));

      // Extract the IDs from activeUsersData and create a Set for faster lookup
      const activeUserIds = new Set(activeUsersData.map((user) => user.id));

      const absentUsersData = absentUsers.data.map((entry) => ({
        id: entry.user.id,
        usersName: entry.user.name,
        initials: entry.user.initials,
        text: entry.type,
        status: "holiday",
      }));

      const notCheckedInUsersData = notCheckedInUsers.data.map((user) => ({
        id: user.id,
        usersName: user.name,
        initials: user.initials,
        text: "Absent",
        status: "absent",
      }));

      dispatch(workersActions.setActive(activeUsersData));
      dispatch(
        workersActions.setAbsent(
          absentUsersData.filter((user) => !activeUserIds.has(user.id))
        )
      );
      dispatch(
        workersActions.setNotCheckedIn(
          notCheckedInUsersData.filter((user) => !activeUserIds.has(user.id))
        )
      );
      dispatch(workersActions.setTotalActive(activeUsersData.length));

      // Return a resolved promise to indicate that fetch is done
      return Promise.resolve();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePullToRefresh = (callback: Function) => {
    fetchUsersData(dispatch)
      .then(() => {
        callback();
      })
      .catch((error) => {
        callback(error);
      });
  };

  return (
    <AppLayout className={styles["workers-list"]} hideHeader={true}>
      <div className={styles["workers-list__header"]}>
        <div className={styles["workers-list__header--search"]}>
          <input placeholder="Enter name.." type="text" />
          <button onClick={() => console.log("Search")}>
            <Search />
          </button>
        </div>

        <button
          className={styles["workers-list__header--close"]}
          onClick={() => navigate("/")}
        >
          <Close />
        </button>
      </div>

      <div className={styles["workers-list__container"]}>
        <PullToRefresh onRefresh={handlePullToRefresh}>
          {activeUsers.map((worker) => (
            <WorkerStatus key={worker.id} worker={worker} />
          ))}

          {absentUsers.map((worker) => (
            <WorkerStatus key={worker.id} worker={worker} />
          ))}

          {notCheckedInUsers.map((worker) => (
            <WorkerStatus key={worker.id} worker={worker} />
          ))}
        </PullToRefresh>
      </div>
    </AppLayout>
  );
};

export default WorkersList;
