import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { CardWrapper } from "../CardWrapper/CardWrapper";
import { MonitorTypeListItem } from "../MonitorTypeListItem/MonitorTypeListItem";

import styles from "./services-overview-types.module.css";

interface Props {
  monitors: any[];
  adjustmentCount?: number;
}
export const AdjustmentOverview = ({ monitors, adjustmentCount }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const openMonitorAdjustmentDetails = (monitorId: any, monitor: any) => {
    navigate(`/adjustments/${monitorId}/details`, { state: { monitor } });
  };

  return (
    <CardWrapper
      title={t("Adjustment")}
      color="gray"
      service="adjustment"
      infestationCount={adjustmentCount}
      addInfoIcon={false}
    >
      {monitors?.map((monitor: any, i: number) => {
        const originalVersion = monitor.versions?.find(
          (version: any) => version.isOriginal === 1
        );

        const changedVersion = monitor.versions?.find(
          (version: any) => version.isOriginal === 0
        );

        const position = originalVersion?.position || changedVersion?.position;
        const location = originalVersion?.location || changedVersion?.location;

        return (
          <MonitorTypeListItem key={i} onClick={() => ({})}>
            <div className={styles["list__item"]}>
              <span className={styles["list__card--monitortype__span"]}>
                {monitor.monitorType.label} {monitor.isTemporary ? "T" : ""}
                {position}, {location}
              </span>
              <img
                src="/img/info-icon.png"
                onClick={() =>
                  openMonitorAdjustmentDetails(monitor.id, monitor)
                }
              />
            </div>
          </MonitorTypeListItem>
        );
      })}
    </CardWrapper>
  );
};
