import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AppointmentCard } from "../../components/AppointmentCard/AppointmentCard";
import styles from "../../components/DashboardHome/dashboard-home.module.css";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { NoData } from "../../components/NoData/NoData";
import { SubMenu } from "../../components/SubMenu/SubMenu";
import { Swipeable } from "../../components/Swipeable/Swipeable";
import { useAppointment } from "../../hooks/useAppointment";
import { useAppointmentClicked } from "../../hooks/useAppointmentClicked";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { appointmentsActions } from "../../store/appointments-slice";
import { ROLES } from "../../store/user-slice";

export const PastAppointments = () => {
  const scrollableWrapper = document.getElementById(
    "scrollable-wrapper"
  ) as HTMLElement;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user);

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isInfiniteScroll, setIsInfiniteScroll] = useState(true);
  const [queryParams, setQueryParams] = useState({
    "page[size]": 20,
    "page[number]": 1,
    "filter[unfinished]": true,
    sort: "-plannedStart",
    include: "documents,incidents.propertyArea",
    ...(user.roles?.includes(ROLES.TECHNICIAN) && { "filter[technicians.id]": user.userIdFromLogin })
  });

  const {
    appointments,
    appointmentsMeta,
    loading,
    fetchAppointments,
  } = useAppointment(queryParams, true, isInfiniteScroll);
  const { handleAppointmentClicked } = useAppointmentClicked();
  const [isFetching, setIsFetching] = useInfiniteScroll(
    scrollableWrapper,
    hasMore
  );

  const resetValues = () => {
    setPage(1);
    setQueryParams((prevParams) => ({
      ...prevParams,
      "page[number]": 1,
    }));
  }

  useEffect(() => {
    resetValues();
  }, []);

  useEffect(() => {
    if (isFetching && isInfiniteScroll) {
      console.log(page, isFetching, isInfiniteScroll)
      setPage((prevPage) => prevPage + 1);
      setIsFetching(false);
    }
  }, [isFetching, isInfiniteScroll]);

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      "page[number]": page,
    });
  }, [page]);

  useEffect(() => {
    if (appointmentsMeta) {
      setHasMore(page < appointmentsMeta?.totalPages);
    }
  }, [appointmentsMeta]);

  const navigateBack = () => {
    dispatch(
      appointmentsActions.setAppointments({
        today: [],
        tomorrow: [],
        future: [],
      })
    );
    navigate("/");
  };

  const handleDeleteAppointment = async () => {
    setIsInfiniteScroll(false);
    setPage(1);
  }

  useEffect(() => {
    if (!isInfiniteScroll) {
      fetchAppointments().then(() => {
        setIsInfiniteScroll(true);
      });
    }
  }, [isInfiniteScroll, page])

  const renderAppointments = () => {
    if (loading && page === 1) {
      return <LoadingSpinner loading={loading} />;
    }

    if (appointments.length > 0) {
      return (
        <>
          {appointments.map((appointment) => (
            <Swipeable
              key={`today-${appointment.id}`}
              appointment={appointment}
              fetchAppointments={handleDeleteAppointment}
            >
              <AppointmentCard
                appointment={appointment}
                past={true}
                onClick={() => handleAppointmentClicked(appointment)}
                isDashboard
              />
            </Swipeable>
          ))}
          {loading && page > 1 && (
            <LoadingSpinner loading={loading} size={20} top="0" />
          )}
        </>
      );
    }
    return <NoData>{t("NoServices")}</NoData>;
  };

  return (
    <AppLayout withBackButton onGoBack={navigateBack}>
      <div className={styles["dashboard-home"]}>
        <div>
          <div className={styles["tasks-today__header"]}>
            <SubMenu showSearchButton={true}>{t("PastAppointments")}</SubMenu>
          </div>

          <div className={styles["tasks-today__list"]}>
            {renderAppointments()}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};
