import { Appointment } from "../types/appointment";
import {
  Deficiency,
  DeficiencyPosition,
  DeficiencyType,
} from "../types/deficiencies";
import { ImageProps } from "../types/image";
import { SevDeskProduct, UsedProduct } from "../types/product";
import { PropertyArea } from "../types/property";
import { ResponseMeta, ResponseWarningQueries } from "../types/response";

import ApiService from "./ApiService";

type IncludesProps = {
  documents?: ImageProps[];
  appointments: Appointment[];
  propertyArea: PropertyArea[];
};

type DeficiencyResponse = {
  data: Deficiency;
  includes?: IncludesProps;
} & ResponseMeta &
  ResponseWarningQueries;

type DeficienciesResponse = {
  data: Deficiency[];
  includes?: IncludesProps;
} & ResponseMeta &
  ResponseWarningQueries;

export type CreateDeficiencyPayload = {
  customer_id: string | null;
  state: "open";
  responsibility: "exterminator" | "customer";
  comment: string;
  property_id: string;
  property_area_id: string;
  location: string;
  required_actions: string[];
  deficiency_type: DeficiencyType | "";
  deficiency_sub_type: string;
  dueDate: string;
  map_position: {
    map_version_id: string;
    map_point: DeficiencyPosition;
  };
};

export type UpdateDeficiencyPayload = {
  state: "processed";
  performedActions?: string[];
  usedProducts?: UsedProduct[];
  sevdeskProducts?: SevDeskProduct[];
  comment?: string;
  processedBy?: {
    id: string;
    type: string;
  }
};

const DeficiencyService = {
  GetDeficiencies: async (): Promise<DeficienciesResponse> => {
    return ApiService.Get("deficiencies", {
      include: "propertyArea",
    });
  },
  GetDeficiency: async (deficiencyId: string): Promise<DeficiencyResponse> => {
    return ApiService.Get(`deficiencies/${deficiencyId}`, {
      include: "propertyArea,appointments",
    });
  },
  CreateDeficiency: async (
    data: CreateDeficiencyPayload
  ): Promise<DeficiencyResponse> => {
    return ApiService.Post("deficiencies", data);
  },
  UpdateDeficiency: async (
    deficiencyId: string,
    data: UpdateDeficiencyPayload
  ): Promise<DeficiencyResponse> => {
    return ApiService.Update(deficiencyId, "deficiencies", data);
  },
  DeleteDeficiency: async (deficiencyId: string): Promise<object> => {
    return ApiService.Delete(deficiencyId, "deficiencies");
  },
};

export default DeficiencyService;
