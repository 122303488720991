import filterDark from "./filter_icon_24px_dark.png";
import filterLight from "./filter_icon_24px_light.png";

type FilterIconProps = {
  color?: "light" | "dark";
};

const FILTER_IMAGES: Record<"light" | "dark", string> = {
  light: filterLight,
  dark: filterDark,
};

export const FilterIcon = ({ color }: FilterIconProps) => (
  <img src={FILTER_IMAGES[color || "light"]} alt="Filter icon" height={24} />
);
