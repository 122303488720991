import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import BackEndService from "../../api/BackEndService";
import DeficiencyService, {
  UpdateDeficiencyPayload,
} from "../../api/DeficiencyService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomCollapse } from "../../components/CustomCollapse/CustomCollapse";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { DeficiencyType } from "../../components/DeficiencyType/DeficiencyType";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../components/Modal/Modal";
import { MultiImageUploader2 } from "../../components/MultiImageUploader2/MultiImageUploader2";
import { TechniciansList } from "../../components/TechniciansList/TechniciansList";
import { useRedirect } from "../../hooks/useRedirect";
import { useService } from "../../hooks/useService";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { clockActions } from "../../store/clock-slice";
import { deficiencyServiceActions } from "../../store/deficiency-service-slice";
import { productSliceActions } from "../../store/products-slice";
import styles from "../../styles/deficiency-service.module.css";
import { ImageProps } from "../../types/image";
import { Technician } from "../../types/user";
import { filterProducts } from "../../utils/filterProducts";
import { getDeficiencyType } from "../../utils/getDeficiencyType";

export const ServiceOnDeficiency = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { deficiencyId } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBackModalOpen, setIsBackModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const { setDashboardUrlAndRedirect } = useRedirect();

  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );

  const customerAdmin = useSelector((state: RootState) => state.user);

  const deficiencyService = useSelector(
    (state: RootState) => state.deficiencyService
  );
  const products = useSelector((state: RootState) => state.products);

  const appointment = useSelector((state: RootState) => state.appointment);
  const { stopService } = useService(appointment);

  const { type, subType } = getDeficiencyType(
    deficiencyService?.deficiency?.deficiencyType,
    deficiencyService?.deficiency?.deficiencySubType
  );

  const [initialComment, setInitialComment] = useState(
    deficiencyService?.comment
  );
  useEffect(() => {
    if (deficiencyService.deficiency && isCustomerAdmin) {
      setInitialComment(deficiencyService.deficiency.comment || "");
      dispatch(
        deficiencyServiceActions.setComment(
          deficiencyService.deficiency.comment || ""
        )
      );
    }
  }, [deficiencyService.deficiency, dispatch]);

  const handleRecommendationChange = (recommendation: string) => {
    dispatch(deficiencyServiceActions.setActions(recommendation));
  };

  const handleMaterialsClicked = () => {
    navigate(`/products`);
  };

  const handleImageUploaded = (image: ImageProps) => {
    dispatch(deficiencyServiceActions.addImage(image));
    dispatch(deficiencyServiceActions.saveAllImages(image));
  };

  const handleImageDelete = (uuid: string) => {
    dispatch(deficiencyServiceActions.removeImage(uuid));
  };

  const handleTextareaChange = (note: string) => {
    setInitialComment(note);
    dispatch(deficiencyServiceActions.setComment(note));
  };

  const endWithoutSignature = () => {
    setIsModalOpen(false);

    handleCloseDeficiencyService();
    dispatch(deficiencyServiceActions.resetState());
    setDashboardUrlAndRedirect("/");
  };

  const endWithSignature = () => {
    console.log("Handle signing from here");

    setLoading(true);

    handleCloseDeficiencyService().then(() => {
      setLoading(false);
      const url = `/deficiencies/${deficiencyId}/overview`;
      setDashboardUrlAndRedirect(url);
    });
  };

  const finishEditAsCustomer = () => {
    setLoading(true);

    handleCloseDeficiencyService().then(() => {
      setLoading(false);
      dispatch(deficiencyServiceActions.setIsCustomerEditing(false));
      setIsModalOpen(true);
    });
  };

  const technicians: Technician[] = [
    {
      id: customerAdmin.currentUser.id,
      firstName: customerAdmin.currentUser.firstName,
      lastName: customerAdmin.currentUser.lastName,
      profileName: customerAdmin.profileName,
    },
  ];

  const handleCloseDeficiencyService = async () => {
    const { sevDeskProducts, usedProducts } = filterProducts(products);

    const updateDeficiencyPayload: UpdateDeficiencyPayload = {
      state: "processed",
      comment: deficiencyService.comment,
      usedProducts,
      sevdeskProducts: sevDeskProducts,
      performedActions: [deficiencyService.actions],
      processedBy: {id: customerAdmin.currentUser.id, type: "User"}
    };

    if (deficiencyService.deficiency) {
      try {
        const deficiencyResult = await DeficiencyService.UpdateDeficiency(
          deficiencyService.deficiency.id,
          updateDeficiencyPayload
        );

        // Upload images and return a promise array
        const imageUploadPromises = deficiencyService.images.map((image) => {
          return BackEndService.AddDocument({
            documentType: "userUpload",
            fileName: image.name,
            isInternal: true,
            media: image.uuid,
            attachedTo: {
              type: "Incident",
              id: deficiencyResult.data.id, // Assuming this is how you get the new deficiency ID
            },
          });
        });

        // Wait for all image uploads to complete before proceeding
        await Promise.all(imageUploadPromises);

        // If deficiency is successfully updated, create time tracking event
        if (!isCustomerAdmin) {
          await stopService(() => {
            dispatch(clockActions.resetClock());
          });
        }
      } catch (error) {
        console.error({ error });
      }
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleBackButtonClicked = () => {
    setIsBackModalOpen(true);
  };

  const endServiceOnBackButton = () => {
    stopService(() => {
      dispatch(clockActions.resetClock());
      dispatch(deficiencyServiceActions.resetServiceData());
      dispatch(productSliceActions.resetState());

      const url = `/deficiencies/${deficiencyService.deficiency?.id}/show`;
      setDashboardUrlAndRedirect(url);
    });
  };

  const redirectToClosedDeficiencies = () => {
    dispatch(deficiencyServiceActions.resetState());

    const url = `/deficiencies?tab=ResolvedReports`;
    setDashboardUrlAndRedirect(url);
  };
  const handleLoadingChange = (loading: boolean) => {
    setImageLoading(loading);
  };
  const isButtonDisabled = deficiencyService.actions?.length === 0;

  return (
    <AppLayout hideHeader>
      {!loading ? (
        <>
          <Modal
            isOpen={isModalOpen}
            message={isCustomerAdmin ? t("TY") : t("ServiceStopModalMessage")}
            note={isCustomerAdmin ? "DefectClosed" : undefined}
            onCancel={isCustomerAdmin ? undefined : endWithoutSignature}
            onConfirm={
              isCustomerAdmin ? redirectToClosedDeficiencies : endWithSignature
            }
            cancelText={isCustomerAdmin ? undefined : "CancelText"}
            confirmText={isCustomerAdmin ? undefined : "ConfirmText"}
          />

          <Modal
            isOpen={isBackModalOpen}
            message={t("ServiceStopModalMessage")}
            onCancel={() => setIsBackModalOpen(false)}
            onConfirm={endServiceOnBackButton}
          />

          <BackButtonHeader
            handleBackButtonClicked={handleBackButtonClicked}
            showDocumentation={false}
          />
          <AppointmentDetails />

          <div className={styles["content-wrapper"]}>
            <DeficiencyType type={type} subType={subType} />

            {isCustomerAdmin && <TechniciansList technicians={technicians} />}

            <div className={styles.recommendations}>
              <CustomInput
                label={t("Actions")}
                placeholder={t("Description")}
                onInputChange={handleRecommendationChange}
                initialValue={deficiencyService.actions}
              />
            </div>

            {!isCustomerAdmin && (
              <CustomCollapse
                onClick={handleMaterialsClicked}
                products={products}
                collapsedByDefault={false}
              >
                {t("Materials")}
              </CustomCollapse>
            )}

            <MultiImageUploader2
              images={deficiencyService.allImages}
              existingImages={
                isCustomerAdmin ? deficiencyService.existingImages : undefined
              }
              onImageUpload={handleImageUploaded}
              onDeleteImage={handleImageDelete}
              onLoadingChange={handleLoadingChange}
            />

            <CustomTextArea
              placeholder={t("AdditionalText")}
              onTextareaChange={handleTextareaChange}
              defaultValue={initialComment}
            />
          </div>

          <FloatingButtons>
            <CustomButton
              color={isCustomerAdmin ? "green" : "red"}
              width={isCustomerAdmin ? 20 : 80}
              icon={isCustomerAdmin ? undefined : "clock"}
              iconPosition={isCustomerAdmin ? undefined : "left"}
              disabled={
                isCustomerAdmin ? false : isButtonDisabled || imageLoading
              }
              onClick={isCustomerAdmin ? finishEditAsCustomer : handleOpenModal}
            >
              {t("CloseDeficiency")}
            </CustomButton>
          </FloatingButtons>
        </>
      ) : (
        <>
          <BackButtonHeader handleBackButtonClicked={handleBackButtonClicked} />
          <AppointmentDetails />
          <LoadingSpinner loading={loading} />
        </>
      )}
    </AppLayout>
  );
};
