import { East } from "@mui/icons-material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf";
import { useSelector } from "react-redux";

import PropertyService from "../../../api/PropertyService";
import { LoadingSpinner } from "../../../components/LoadingSpinner/LoadingSpinner";
import { NoData } from "../../../components/NoData/NoData";
import { SubMenuButton } from "../../../components/SubMenuButton/SubMenuButton";
import { useDocumentation } from "../../../hooks/useDocumentation";
import { DocumenteIcon } from "../../../icons/DocumenteIcon/DocumenteIcon";
import { DownloadDocument } from "../../../icons/DownloadDocument/DownloadDocument";
import { ReturnArrowIcon } from "../../../icons/ReturnArrowIcon/ReturnArrowIcon";
import { AppLayout } from "../../../layout/AppLayout/AppLayout";
import { RootState } from "../../../store";
import styles from "../../../styles/customer-monitors.module.css";

import stylesDocuments from "./documente.module.css";

export const Documente = () => {
  const { t } = useTranslation();
  const selectedProperty = useSelector(
    (state: RootState) => state.user?.selectedProperty?.id
  );
  const documentItems = [
    { id: "1", name: "Gefährdungsbeurteilung", value: "riskAssessment" },
    { id: "2", name: "Allgemein", value: "general" },
  ];
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("Gefährdungsbeurteilung");
  const [documents, setDocuments] = useState<any[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<any | null>(null);
  const [filteredDocuments, setFilteredDocuments] = useState<any[]>([]);
  const [chosenDocumentType, setChosenDocumentType] = useState<string | null>(
    "riskAssessment"
  );
  const [numPages, setNumPages] = useState<number>();

  useEffect(() => {
    setLoading(true);
    setSelectedDocument(null);
    setChosenDocumentType("riskAssessment");
    PropertyService.GetProperty(selectedProperty)
      .then((result) => {
        const documentsWithNames = result.includes.documents.map(
          (doc: any) => ({
            ...doc,
            name: doc.fileName,
          })
        );
        setDocuments(documentsWithNames);
        setFilteredDocuments(documentsWithNames);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [selectedProperty]);

  const onSelectType = (type: any) => {
    setChosenDocumentType(type.value);
    setCurrentTab(type.name);
  };
  const { fetchDocumentation } = useDocumentation();

  useEffect(() => {
    if (chosenDocumentType !== null) {
      const filtered = documents
        .filter((doc) => doc.category === chosenDocumentType)
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      setFilteredDocuments(filtered);
    }
  }, [chosenDocumentType, documents]);
  const onSelectDocument = (document: any) => {
    setSelectedDocument(document);
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  return (
    <AppLayout>
      {loading && <LoadingSpinner loading={loading} />}
      {!selectedDocument && !loading && (
        <div className={styles["customer-monitors"]}>
          <div className={styles["customer-monitors__heading"]}>
            <p className={styles["customer-monitors__title"]}>
              <DocumenteIcon /> Dokumente
            </p>
          </div>

          <div className={styles["tabs"]}>
            <div className={styles["buttons"]}>
              {documentItems.length > 0 &&
                documentItems.map((button, i) => (
                  <SubMenuButton
                    currentTab={currentTab}
                    value={button.name}
                    onSetTab={() => onSelectType(button)}
                    key={i}
                  />
                ))}
            </div>
          </div>
          <div className={stylesDocuments["header"]}>
            <p
              className={`${stylesDocuments["documents-flex__1"]} ${stylesDocuments["name"]}`}
            >
              Name
            </p>
            <p
              className={`${stylesDocuments["documents-flex__2"]} ${stylesDocuments["name"]}`}
            >
              zuletzt geändert
            </p>
          </div>
          {filteredDocuments.length === 0 ? (
            <NoData>{t("NoDocumentsAvailable")}</NoData>
          ) : (
            filteredDocuments.map((document: any) => (
              <div
                className={stylesDocuments["documents"]}
                key={document.id}
                onClick={() => {
                  onSelectDocument(document);
                }}
              >
                <div className={stylesDocuments["documents-flex__1"]}>
                  <p className={stylesDocuments["document-name"]}>
                    {document.name}
                  </p>
                </div>

                <div className={stylesDocuments["documents-flex__2"]}>
                  <p className={stylesDocuments["document-date"]}>
                    {document.changedAt
                      ? moment(document.changedAt).format("DD.MM.yyyy.")
                      : moment(document.createdAt).format("DD.MM.yyyy.")}
                  </p>
                  <East className={stylesDocuments["document-date"]}></East>
                </div>
              </div>
            ))
          )}
        </div>
      )}

      {selectedDocument && (
        <div>
          <div className={stylesDocuments["header-preview"]}>
            <div className={stylesDocuments["header-icon"]}>
              <ReturnArrowIcon
                handleBackButtonClicked={() => {
                  setSelectedDocument(!selectedDocument);
                }}
              />
              <p className={stylesDocuments["document-name"]}>
                {selectedDocument.name}
              </p>
            </div>

            <div className={stylesDocuments["back-header__download"]}>
              <p>{t("DownloadDocumentation")}</p>
              <div className={stylesDocuments["cursor"]}>
                <DownloadDocument
                  handleDownloadButtonClicked={() => {
                    fetchDocumentation(selectedDocument.url);
                  }}
                />
              </div>
            </div>
          </div>

          <div className={stylesDocuments["pdf-preview"]}>
            <Document
              file={selectedDocument.url}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<LoadingSpinner loading />}
            >
              {[...Array(numPages)].map((_, index) => (
                <Page
                  key={index}
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  width={900}
                />
              ))}
            </Document>
          </div>
        </div>
      )}
    </AppLayout>
  );
};
